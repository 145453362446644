import Send from '../../cp.client.js'

export default {
  getTargetInfo (scenarioCd) {
    return Send({
      url: '/cp/target/info',
      method: 'get',
      params: {
        scenarioCd: scenarioCd
      }
    })
  },
  getTargetCondList (scenarioCd) {
    return Send({
      url: '/cp/target/list',
      method: 'get',
      params: {
        scenarioCd: scenarioCd
      }
    })
  },
  saveScenarioTarget (data) {
    return Send({
      url: '/cp/target/save',
      method: 'put',
      data: data
    })
  },
  factorCompareList (param) {
    //return new Promise((resolve, reject) => {
    return Send({
      url: '/cp/target/factorCompareList',
      method: 'get',
      //async: false,
      params: {
        factorCd: param.factorCd,
        factorCdC: param.factorCdC
      }
      //}).then(res => {
      //  resolve(res)
      //}).catch((err) => {
      //  reject(err)
      //})
    })
  },
  targetGroupby (param) {
    return Send({
      url: '/cp/target/targetGroupbyList',
      method: 'get',
      params: {
        scenarioCd: param.scenarioCd,
        porCtrYn: param.porCtrYn,
        porPlcYn: param.porPlcYn,
        dlyCtrYn: param.dlyCtrYn,
        dlyPlcYn: param.dlyPlcYn,
        routeYn: param.routeYn,
        itemYn: param.itemYn,
        cntrSzTypYn: param.cntrSzTypYn,
        frtPncYn: param.frtPncYn,
        bidgCstYn: param.bidgCstYn,
        bkgShipperYn: param.bkgShipperYn,
        actShipperYn: param.actShipperYn,
        hcneShipperYn: param.hcneShipperYn,
        profileYn: param.profileYn,
        targetConfirmed: param.targetConfirmed,
        isEntry: param.isEntry,
        afterEntry: param.afterEntry
      }
    })
  },
  targetConfirmed (chkObj, list) {
    const req = {
      controlPanelList: [],
      groupKeyCheck: {}
    }
    req.controlPanelList = list
    req.groupKeyCheck = chkObj
    return Send({
      url: '/cp/target/targetConfirmed',
      method: 'put',
      data: req
    })
  },
  mcs (chkObj, list) {
    const req = {
      controlPanelList: [],
      groupKeyCheck: {}
    }
    req.controlPanelList = list
    req.groupKeyCheck = chkObj
    return Send({
      url: '/cp/target/mcs',
      method: 'put',
      data: req
    })
  },
  salesforceOpportunity (list) {
    return Send({
      url: '/cp/target/salesforceOpportunity',
      method: 'put',
      data: {
        salesforceTransferList: list
      }
    })
  },
  salesforceUserCheck (userId) {
    return Send({
      url: '/cp/target/salesforceUserCheck',
      method: 'get',
      params: {
        userId: userId
      }
    })
  },
  salesforcePromotion (list) {
    return Send({
      url: '/cp/target/salesforcePromotion',
      method: 'put',
      data: {
        salesforceTransferList: list
      }
    })
  },
  scenarioPromotionCheck (list) {
    return Send({
      url: '/cp/target/scenarioPromotionCheck',
      method: 'put',
      data: {
        salesforceTransferList: list
      }
    })
  },
  salesforcePromotionActivity (list) {
    return Send({
      url: '/cp/target/salesforcePromotionActivity',
      method: 'put',
      data: {
        salesforceTransferList: list
      }
    })
  },
  opportunityTransferLog (scenarioCd) {
    return Send({
      url: '/cp/target/opportunityTransferLog',
      method: 'get',
      params: {
        scenarioCd: scenarioCd
      }
    })
  },
  promotionTransferLog (scenarioCd) {
    return Send({
      url: '/cp/target/promotionTransferLog',
      method: 'get',
      params: {
        scenarioCd: scenarioCd
      }
    })
  },
  promotionActivityTransferLog (scenarioCd) {
    return Send({
      url: '/cp/target/promotionActivityTransferLog',
      method: 'get',
      params: {
        scenarioCd: scenarioCd
      }
    })
  },
  deleteTempFactorOption (data) {
    return Send({
      url: '/cp/target/deleteTemp',
      method: 'put',
      data: data
    })
  },
  profileEmailList (list) {
    const req = {
      controlPanelList: []
    }
    req.controlPanelList = list
    return new Promise((resolve, reject) => {
      return Send({
        url: '/cp/target/profileEmailList',
        method: 'put',
        data: req
      }).then(res => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}
